const TRANSLATIONS = {
    submit: "ดำเนินการต่อ",
    // cancel: "Cancel",

    // password_title: "Enter password to proceed",
    // password: "Password",
    // password_step_details: "Filling up this form will create a user on Proship and associate the user with Thaipost automatically.",

    // name: "Name",
    // email: "Email",
    // password: "Password",
    // phone_no: "Phone Number",
    // user_step_details: "Based on these info, a new user will be registered on Proship.",

    // generate_password_title: "Generate password",
    // generate_password_btn: "Generate password",
    // lowercase: "Lowercase",
    // uppercase: "Uppercase",
    // numbers: "Numbers",
    // symbols: "Symbols",
    // length: "Length",

    // thp_username: "Thp Username",
    // thp_password: "Thp Password",
    // prefix: "Prefix",
    // prefixType: "Prefix Type",
    // ems: "E(EMS)",
    // eco_post: "O(ECO-POST)",
    // licenseNo: "License No.",
    // thp_step_details: "Fill up the form with Thaipost user details.",
    // thp_step_note: "Note: If details already exists on system, new user will be asociated with that THP user.",

    // confirm_step_details: "Verify the information once again to ensure it's all correct.",
    // confirm_step_note: "On success, you will be redirected to Proship.",

    message_password: "Can also be auto generated 👉",
    "cancel": "กดยกเลิก",
    "password_title": "กรอกรหัสผ่านเพื่อดำเนินการต่อ",
    "password": "รหัสผ่านที่ต้องการ",
    "password_step_details": "ระบบการเปิด USER พร้อมเชื่อมต่อ eParcel API สำหรับฝากส่งกับปณท.",
    "name": "ชื่อผู้ฝากส่ง",
    "email": "อีเมลล์",
    "password": "รหัสผ่านที่ต้องการ",
    "phone_no": "เบอร์มือถือ",
    "user_step_details": "ข้อมูลส่วนนี้จะใช้สำหรับการเปิดระบบ PROSHIP โปรดจำข้อมูลให้ถูกต้อง",
    "generate_password_title": "ออกรหัสผ่าน",
    "generate_password_btn": "กดออกรหัสผ่าน",
    "lowercase": "ใช้ภาษาอังกฤษตัวเล็ก",
    "uppercase": "ใช้ภาษาอังกฤษตัวใหญ่",
    "numbers": "ใช้ตัวเลข",
    "symbols": "อักษรพิเศษ",
    "length": "ความยาว",
    "message_password": "ระบบออกรหัสผ่านอัตโนมัติ",
    
    "thp_username": "Username eParcel API",
    "thp_password": "Password",
    "prefix": "Manifest Prefix",
    "prefix_type": "หมวดเลขบารโค้ด",
    "ems": "E(EMS)",
    "eco_post": "O(ECO-POST)",
    "license_no": "เลขที่สัญญา:",
    "thp_step_details": "กรอกข้อมูลส่วนนี้ตามที่ ปณท. ได้ส่งข้อมูลมา",
    "thp_step_note": "หาก Username eParcel API มีในระบบแล้ว ระบบจะออก User ใหม่ที่เชื่อมกันกับ API ตามนี้",

    "confirm_step_details": "รบกวนยืนยันข้อมูลว่าถูกต้องแล้ว และกกด ปุ่ม ยืนยัน",
    "confirm_step_note": "หากทำเสร็จ ระบบจะนำสู่หน้าเข้าระบบ PROSHIP โปรดอย่าลืมข้อมูลที่กรอก"
}

export const trans = (key) => TRANSLATIONS[key];