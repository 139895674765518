<template>
  <div id="app" class="container d-flex justify-content-center pt-5">
    <!-- Step 1: password -->
    <div class="step align-self-center" id="step-1" v-if="step === 1">
      <div class="card" style="min-width: 500px">
        <div class="card-body" style="padding: 0">
          <div class="d-flex">
            <div
              class="info p-4 text-center"
              style="
                width: 200px;
                background-color: whitesmoke;
                color: #373737;
                border-radius: 0.8rem;
              "
            >
              <img
                src="@/assets/logo_proship.png"
                class="img-fluid"
                style="width: 105px; height: auto"
              />

              <p class="text-center mt-2 --font-sm">
                {{ trans("password_step_details") }}
              </p>
            </div>

            <div class="ui p-4">
              <p class="lead">{{ trans("password_title") }}</p>
              <input
                type="password"
                class="form-control mb-4 mt-4"
                id="secret-password"
                v-model="inputPass"
              />

              <div class="text-center">
                <button type="button" class="btn btn-primary" @click="auth()">
                  {{ trans("submit") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Step 2: user details -->
    <div class="step align-self-center" id="step-2" v-if="step === 2">
      <div class="card">
        <div class="card-body p-0">
          <div class="d-flex">
            <div
              class="info p-4 text-center"
              style="
                width: 200px;
                background-color: whitesmoke;
                color: #373737;
                border-radius: 0.8rem;
              "
            >
              <img
                src="@/assets/logo_proship.png"
                class="img-fluid"
                style="width: 105px; height: auto"
              />

              <p class="text-center mt-2 --font-sm">
                {{ trans("user_step_details") }}
              </p>
            </div>

            <div class="ui p-4">
              <div class="form">
                <div class="row mb-4">
                  <div class="col">
                    <!-- Name -->
                    <label for="username">{{ trans("name") }}</label>
                    <input
                      type="text"
                      class="form-control form-control-sm mb-3"
                      id="username"
                      v-model="form.username"
                      :placeholder="trans('name')"
                    />
                  </div>

                  <div class="col">
                    <!-- Email -->
                    <label for="email">{{ trans("email") }}</label>
                    <input
                      type="email"
                      class="form-control form-control-sm mb-3"
                      id="email"
                      v-model="form.email"
                      :placeholder="trans('email')"
                    />
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col">
                    <!-- Password -->
                    <label for="password">{{ trans("password") }}</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="password"
                      v-model="form.password"
                      :placeholder="trans('password')"
                      @input="form.passwordConfirmation = form.password"
                      disabled
                    />
                    <GeneratePassword @generated="handlePasswordGenerate" />
                  </div>

                  <div class="col">
                    <!-- Phone No -->
                    <label for="phoneNo">{{ trans("phone_no") }}</label>
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      id="phoneNo"
                      v-model="form.phoneNo"
                      :placeholder="trans('phone_no')"
                      @keyup="validatePhoneNo"
                    />
                    <span
                      class="text-danger --font-vsm"
                      v-if="errors.phoneNo && errors.phoneNo.length"
                    >
                      {{ errors.phoneNo[0] }}
                    </span>
                  </div>
                </div>

                <div class="d-flex justify-content-end">
                  <button
                    type="button"
                    class="btn btn-secondary btn-sm"
                    @click="cancel()"
                  >
                    {{ trans("cancel") }}
                  </button>

                  <div class="m-2"></div>

                  <button
                    type="button"
                    class="btn btn-primary btn-sm"
                    @click="validateUserDetails()"
                    :disabled="steps[2].loading || !steps[2].valid"
                  >
                    {{ trans("submit") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Step 3: thaipost -->
    <div class="step align-self-center" id="step-3" v-if="step === 3">
      <div class="card">
        <div class="card-body p-0">
          <div class="d-flex">
            <div
              class="info p-4 text-center"
              style="
                width: 200px;
                background-color: whitesmoke;
                color: #373737;
                border-radius: 0.8rem;
              "
            >
              <img
                src="@/assets/logo_proship.png"
                class="img-fluid"
                style="width: 105px; height: auto"
              />

              <p class="text-center mt-2 --font-sm">
                {{ trans("thp_step_details") }}
              </p>

              <span class="text-muted --font-vsm">
                {{ trans("thp_step_note") }}
              </span>
            </div>

            <div class="ui p-4">
              <div class="form h-100">
                <div class="d-flex flex-column h-100">
                  <div class="row mb-2 flex-grow-1">
                    <div class="col">
                      <label>{{ trans("prefix_type") }}</label>
                      <br />

                      <div class="form-check form-check-inline">
                        <input
                          type="radio"
                          id="E"
                          value="E"
                          class="form-check-input"
                          v-model="form.prefixType"
                        />
                        <label for="E" class="form-check-label">{{
                          trans("ems")
                        }}</label>
                      </div>

                      <div class="form-check form-check-inline">
                        <input
                          type="radio"
                          id="O"
                          value="O"
                          class="form-check-input"
                          v-model="form.prefixType"
                          disabled
                        />
                        <label for="O" class="form-check-label">{{
                          trans("eco_post")
                        }}</label>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex justify-content-end">
                    <button
                      type="button"
                      class="btn btn-secondary btn-sm"
                      @click="cancel()"
                    >
                      {{ trans("cancel") }}
                    </button>
                    <div class="m-2"></div>
                    <button
                      type="button"
                      class="btn btn-primary btn-sm"
                      @click="validateThpDetails()"
                      :disabled="steps[3].loading || !steps[3].valid"
                    >
                      {{ trans("submit") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Step 4: confirm -->
    <div class="step align-self-center" id="step-4" v-if="step === 4">
      <div class="card" style="min-width: 500px">
        <div class="card-body p-0">
          <div class="d-flex">
            <div
              class="info p-4 text-center"
              style="
                width: 200px;
                background-color: whitesmoke;
                color: #373737;
                border-radius: 0.8rem;
              "
            >
              <img
                src="@/assets/logo_proship.png"
                class="img-fluid"
                style="width: 105px; height: auto"
              />

              <p class="text-center mt-2 --font-sm">
                {{ trans("confirm_step_details") }}
              </p>

              <span class="text-info --font-vsm">
                {{ trans("confirm_step_note") }}
              </span>
            </div>

            <div class="ui p-4">
              <div class="h-100 d-flex flex-column">
                <div class="flex-grow-1">
                  <table class="table --font-sm">
                    <tr>
                      <td class="bold">{{ trans("name") }}:</td>
                      <td>{{ form.username }}</td>
                    </tr>
                    <tr>
                      <td class="bold">{{ trans("email") }}:</td>
                      <td>{{ form.email }}</td>
                    </tr>
                    <tr>
                      <td class="bold">{{ trans("password") }}:</td>
                      <td>
                        {{ form.password }}

                        <button
                          type="button"
                          class="
                            btn btn-outline-secondary btn-sm
                            m-1
                            --font-vsm
                          "
                          @click="copyCreds()"
                          style="padding: 0.1rem 0.5rem; height: 1.5rem"
                        >
                          Copy
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td class="bold">{{ trans("phone_no") }}:</td>
                      <td>{{ form.phoneNo }}</td>
                    </tr>
                    <tr>
                      <td class="bold">{{ trans("prefix_type") }}:</td>
                      <td>{{ form.prefixType }}</td>
                    </tr>
                  </table>
                </div>

                <div class="d-flex justify-content-end mt-3">
                  <button
                    type="button"
                    class="btn btn-secondary btn-sm"
                    @click="cancel()"
                  >
                    {{ trans("cancel") }}
                  </button>
                  <div class="m-2"></div>
                  <button
                    type="button"
                    class="btn btn-primary btn-sm"
                    @click="createUser()"
                    :disabled="steps[4].loading"
                  >
                    {{ trans("submit") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import GeneratePassword from "@/components/GeneratePassword";
import Stepper from "@/components/Stepper";
import { trans } from "@/translations";

// const URL = "http://localhost:3000/dev/v1/auth/register-thaipost-zero";
const URL =
  "https://tbtrglpsa0.execute-api.ap-southeast-1.amazonaws.com/dev/v1/auth/register-thaipost-zero";

export default {
  name: "App",
  components: { GeneratePassword, Stepper },
  data() {
    return {
      step: 1,

      steps: {
        1: {
          valid: false,
        },

        2: {
          valid: true,
          loading: false,
        },
        3: {
          valid: true,
          loading: false,
        },
        4: {
          loading: false,
        },
      },

      inputPass: null,
      secretPass: "28wALvTjt6EPm9L2",

      form: {
        action: null,

        // user details
        username: null,
        phoneNo: null,
        email: null,
        password: null,
        passwordConfirmation: null,
        prefixType: "E",

        // // user details
        // username: "asd",
        // phoneNo: "01234567890",
        // email: "asdas@proship.com",
        // password: "123456",
        // passwordConfirmation: "123456",
      },

      errors: {
        username: null,
        email: null,
        phoneNo: null,
      },
    };
  },

  methods: {
    async copyCreds() {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(
          `Email: ${this.form.email}\nPassword: ${this.form.password}`
        );
        this.$toast.open({ message: "Copied Credentials", type: "success" });
      } else {
        this.$toast.open({ message: "Error copying", type: "error" });
      }
    },

    trans(key) {
      return trans(key);
    },

    auth() {
      if (this.inputPass === this.secretPass) this.step = 2;
      else this.$toast.open({ message: "Invalid credentials", type: "error" });
    },

    cancel() {
      this.step--; // user Details
    },

    validateUserDetails() {
      if (
        this.isEmpty(this.form.username) ||
        this.isEmpty(this.form.phoneNo) ||
        this.isEmpty(this.form.email) ||
        this.isEmpty(this.form.password)
      ) {
        this.$toast.open({ message: "All values must exist", type: "error" });
        return;
      }

      this.form.action = 1;
      this.steps[2].loading = true;
      this.step = 3;
      this.steps[2].loading = false;
    },

    validateThpDetails() {
      if (this.isEmpty(this.form.prefixType)) {
        this.$toast.open({ message: "All values must exist", type: "error" });
        return;
      }

      this.form.action = 2;
      this.steps[3].loading = true;
      this.step = 4;
      this.steps[3].loading = false;
    },

    createUser() {
      this.steps[4].loading = true;
      this.form.action = 3;
      axios
        .post(URL, this.form)
        .then((resp) => {
          this.steps[4].loading = false;
          this.$toast.open({
            message: "Redirecting to Proship",
            type: "success",
          });
          setTimeout(() => {
            window.open("https://app.proship.co.th/#/login", "_blank");
          }, 1500);
        })
        .catch((e) => {
          this.$toast.open({ message: e.response.data.message, type: "error" });
          this.steps[4].loading = false;
        });
    },

    isEmpty(value) {
      if (!value || !value.length) return true;
      return false;
    },

    handlePasswordGenerate(strongPass) {
      this.form.password = strongPass;
      this.form.passwordConfirmation = strongPass;
    },

    validatePhoneNo(e) {
      this.errors.phoneNo = [];

      let phone = this.form.phoneNo + "";
      if (!phone || !phone.length) this.errors.phoneNo.push("Invalid");

      if (phone.charAt(0) !== "0")
        this.errors.phoneNo.push("Must begin with zero");

      if (phone.length != 10) this.errors.phoneNo.push("Must have 10 digits");
    },
  },
};
</script>

<style lang="scss">
#app {
  height: 100vh;
  width: 100vh;
  background-color: #d2d6de;
}

.bold {
  font-weight: bold;
}

.--font-sm {
  font-size: 0.9rem;
}
.--font-vsm {
  font-size: 0.8rem;
}

label {
  font-size: 0.9rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>