<template>
  <!-- <div class="generatePassword"> -->
  <button class="btn btn-secondary btn-sm w-100 mt-2" @click="generate()">
    Generate Password
  </button>
  <!-- </div> -->
</template>

<script>
const generator = require("generate-password");
import { trans } from "@/translations";

export default {
  name: "GeneratePassword",

  data() {
    return {
      lowercase: true,
      uppercase: false,
      numbers: true,
      symbols: false,
      length: 10,
      strict: true,
    };
  },

  methods: {
    trans(key) {
      return trans(key);
    },

    generate() {
      let password = generator.generate({
        length: this.length,
        numbers: this.numbers,
        symbols: this.symbols,
        uppercase: this.uppercase,
        lowercase: this.lowercase,
        strict: this.strict,
      });
      this.$emit("generated", password);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.generatePassword {
  background-color: #f7f8fc;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;

  font-size: 0.8rem;

  label {
    font-size: 0.8rem;
  }

}
  button {
    font-size: 0.8rem;
  }
</style>
